import React, {useState} from 'react'
import Roll from 'react-reveal'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import SuccessFormModal from './modals/successFormModal'
const Footer = () => {
    const [subscribed, setSubscribed] = useState(false)
    const [ email, setEmail ] = useState()
    const [msg, setMsg ] = useState('')
    const subscribeEmail = (e) => {
        setEmail(e)
    }
    const handleSubmit = (e) => {
        e.preventDefault()  
        if(email) {
            addToMailchimp(email)
                .then(data => {   
                    const splitMsg =data.msg.split('<')
                    if(data.msg) {
                        setSubscribed(true)	                                    
                        setMsg(splitMsg[0])
                    }
                })
                .catch(err => console.log('eerr',err))
        }   
    }
    
    return (
        <div>
            {/*<div className="flex flex-wrap justify-center bg-mainColor text-white p-6">
                <div className="grid grid-cols-1 md:grid-cols-3 mb-2 w-full">
                    <div className="w-full  text-center ">
                        <h4 className="text-xl py-4">About Us</h4>
                        <ul className="p-0">
                            <li><Link className="text-white no-underline" to={'/'}>Company</Link></li>
                            <li><Link className="text-white no-underline" to={'/'}>Cookies Policy</Link></li>
                            <li><Link className="text-white no-underline" to={'/'}>Policy </Link></li>
                        </ul>
                    </div>
                    <div className="w-full text-white text-center">
                        <h4 className="text-xl py-4">Connect</h4>
                        <ul className="p-0">

                            <li><Link className="text-white no-underline" to={'/'}>Blog</Link></li>
                            <li><Link className="text-white no-underline" to= {'/'}>Contact us</Link></li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <h4 className="text-xl py-4">Subscribe</h4>*
                        <form onSubmit={(e)=>handleSubmit(e)}>
                            <div className="mb-4">
                                <input onChange={(e)=>subscribeEmail(e.target.value)} className="bg-gray-100 w-2/3  appearance-none border-4 border-yellowColor rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-100" id="inline-full-name" type="email" required placeholder="Email"/>
                            </div>
                            <button  className="bg-yellowColor font-bold  text-mainColor transition-shadow py-2 px-4 rounded" type="submit">Subscribe</button>
                            {subscribed ? <p className="mt-2">Thank you for subscribing with us!</p> : null}
                        </form>
                    </div>
                </div>
            </div> */}
            <Roll>
                <div className="pt-5 bg-bgColor text-center">
                    <div className="max-w-screen-xl w-4/5 md:w-full m-auto">
                        <p className="text-xl font-semibold text-mainColor">Subscribe to our newsletter</p>
                        <span className="text-mainColor">Join our community and get access to exclusive content and software!</span>    
                        <form className="md:flex sm:w-2/5 m-auto justify-center p-3" onSubmit={(e)=>handleSubmit(e)}>
                            <input onChange={(e)=>subscribeEmail(e.target.value)} className="bg-lightBlue field w-full  appearance-none  md:rounded-l-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-100" id="inline-full-name" type="email" required placeholder="Enter your email address"/>
                            <button  className="bg-mainColor font-bold text-center text-yellowColor p-2 px-4 md:rounded-r" type="submit">Subscribe</button>
                        </form>
                        {subscribed ? <p className="my-2">Thank you for subscribing with us!</p> : null}
                    </div>
                </div>
            </Roll>
            <hr className="m-0"/> 
            <div className="bg-white text-white pl-6 p-2 text-center grid grid-cols-1 md:grid-cols-3 mb-2 max-w-screen-2xl md:w-full m-auto">
                <div><a href="/cookie-policy" className="text-mainColor">Cookies Policy</a></div>
                <div className="bottom text-mainColor">Copyright &copy; Ricta Technologies SRL {new Date ().getFullYear()}. All rights reserved</div>
                <div><a href="/privacy-policy" className="text-mainColor">Privacy Policy</a></div>
            </div>
            {subscribed && <SuccessFormModal modalIsOpen={subscribed} setModalIsOpen={setSubscribed} text={msg}/>}
        </div>
        
    )

}
export default Footer